export const MAX_ALLOWED_SUBSCRIBED = 100;

export type Product = {
  productId: string;
  description: string | null;
  name: string;
  images: string[];
  prices: PriceType[];
  lo_max_allowed_ac: number;
  is_contact_required: boolean;
  order: number | null;
  bullets: string[];
};

export type PriceType = {
  currency: string;
  priceId: string;
  recurring: {
    interval: EIntervals;
    interval_count: number;
  };
  unit_amount: number;
  tiers_mode: string;
  tiers: Tier[];
};

export type Tier = {
  flat_amount: null | number;
  unit_amount: number;
  unit_amount_decimal: string;
  up_to: number | null;
};

export type Coupon = {
  id: string;
  object: string;
  amount_off: null | number;
  created: Date;
  currency: null | string;
  duration: string;
  name: string;
  percent_off: number;
  valid: boolean;
};

export type Subscription = {
  alreadyConnectedAdAccountsCount: number;
  organizationId: string;
  id: string;
  active: boolean;
  amount: number;
  status: SubscriptionStatuses;
  cratedAt: string;
  trialExpirationDate: string;
  type: SubscriptionType;
  externalCreated: Date;
  externalCustomerId: string;
  externalPriceId: string;
  externalProductId: string;
  externalSubscriptionId: string;
  interval: EIntervals;
  max_allowed_ac: number;
  plan: 'string';
  extra_allowed_ac: number;
};

export enum EIntervals {
  MONTH = 'month',
  YEAR = 'year',
}

export enum SubscriptionType {
  TRIAL = 'trial',
  BLOCKED = 'blocked',
  PAUSED = 'paused',
  PAYED = 'payed',
  DELETED = 'deleted',
}

export enum SubscriptionStatuses {
  ACTIVE = 'active',
  CANCELED = 'canceled',
  INCOMPLETE = 'incomplete',
  PAST_DUE = 'past_due',
  INCOMPLETE_EXPIRED = 'incomplete_expired',
  TRIAL = 'trial',
}

export type StateModel = {
  isShown: boolean;
  shouldBlock: boolean;
  isMaxConnected: boolean;
  bannerMessage: string;
};
