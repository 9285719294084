import { useState } from 'react';

export enum Order {
  ASC = 'asc',
  DESC = 'desc',
}

export const ORDER_TRANSFORM = {
  [Order.ASC]: 1,
  [Order.DESC]: -1,
};

export const useTableSorting = <T extends string>({
  initialOrder = Order.DESC,
  initialOrderBy,
}: {
  initialOrder?: Order;
  initialOrderBy: T;
}) => {
  const [order, setOrder] = useState<Order>(initialOrder);
  const [orderBy, setOrderBy] = useState<T>(initialOrderBy);

  const handleRequestSort = (property: T) => {
    const isDesc = orderBy === property && order === Order.DESC;
    setOrder(isDesc ? Order.ASC : Order.DESC);
    setOrderBy(property);
  };

  return { order, orderBy, handleRequestSort };
};
