import { createTheme, ThemeOptions } from '@mui/material';
import SortArrow from 'assets/svg/sort_arrow.svg?react';

export const themeOptions: ThemeOptions = createTheme({
  breakpoints: {
    values: {
      xs: 768,
      sm: 1024,
      md: 1440,
      lg: 1920,
      xl: 2400,
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#0A63B1',
      dark: '#2E196B',
      light: '#EBE9FD',
    },
    secondary: {
      main: '#F2F2F2',
      dark: '#84929E',
      light: '#DFE2EA',
    },
    background: {
      default: '#121212',
      paper: '#2F2E2E',
    },
    text: {
      primary: '#FFF',
      secondary: '#565E64',
    },
    error: {
      main: '#FF0F00',
    },
    warning: {
      main: '#F95C35',
      light: '#F95C35',
      dark: '#FFB155',
    },
    success: {
      main: '#3BB877',
      light: '#008DBA',
    },
    info: {
      main: '#DFE2EA',
      light: '#D8EEFF',
      dark: '#3277B6',
    },
    divider: '#F2F2F2',
    action: {
      selected: '#F9F9FF',
      disabled: '#84929E',
      disabledBackground: '#D9D9D9',
    },
  },
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontWeightRegular: 500,
    fontWeightMedium: 700,
    fontWeightLight: 400,
    body1: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '18px',
    },
    body2: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
    },
    h1: {
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: '24px',
    },
    h2: {
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '31px',
    },
    h3: {
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '26px',
    },
    h4: {
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '20px',
    },
    h5: {
      fontWeight: 500,
      fontSize: '32px',
      lineHeight: '39px',
    },
    h6: {
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '31px',
    },
    caption: {
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: 1.4,
      color: '#5523E5',
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '16px',
    },
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          display: 'block',
          color: theme.palette.text.primary,
        }),
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        autoComplete: 'off',
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontWeight: 400,

          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.primary,
          '& .MuiOutlinedInput-input': {
            padding: '15px 21px 14px',
          },
          '&.Mui-readOnly': {
            color: theme.palette.secondary.dark,
          },
          '&.MuiOutlinedInput-root': {
            borderRadius: 12,
            '& fieldset': {
              borderColor: theme.palette.secondary.main,
              borderRadius: 12,
            },
            '&:hover fieldset': {
              borderColor: theme.palette.secondary.main,
            },
            '&.Mui-focused fieldset': {
              borderColor: theme.palette.secondary.main,
              border: '1px solid',
            },
          },
        }),
        input: ({ theme }) => ({
          height: 17,
          '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 1000px white inset',
            WebkitTextFillColor: theme.palette.text.primary,
          },
          '&::placeholder': {
            fontWeight: 400,
            color: theme.palette.secondary.dark,
            opacity: 1,
          },
        }),
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          position: 'absolute',
          bottom: '-15px',
          fontSize: '12px',
          lineHeight: '15px',
          fontWeight: 400,
          margin: 0,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.primary,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }),
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          zIndex: 20,
          backgroundColor: 'rgba(20, 20, 45, 0.50)',
        },
        invisible: {
          backgroundColor: 'transparent',
          pointerEvents: 'auto',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        disableElevation: true,
        disableRipple: false,
      },
      variants: [
        {
          props: { color: 'secondary' },
          style: {
            '&:hover': {
              backgroundColor: '#40406C',
            },
            borderColor: '#40406C',
          },
        },
        {
          props: { color: 'primary' },
          style: {
            '&:hover': {
              backgroundColor: '#5523e5',
            },
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            backgroundColor: '#fFF',
            '&:hover': {
              backgroundColor: '#fFF',
            },
            '&.Mui-disabled': {
              color: '#84929E',
              backgroundColor: '#fFF',
              borderColor: '#84929E',
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          height: 40,
          minWidth: 140,
          borderRadius: 30,
          textTransform: 'none',
          fontSize: 14,
          lineHeight: '18px',
          fontWeight: 400,
          padding: '11px 43px',
          boxShadow: 'none',

          '&:hover': {
            boxShadow: 'none',
          },
          '&:disabled': {
            backgroundColor: '#84929E',
            color: '#fff',
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,
          width: '100%',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          margin: 0,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: '0px 10px 10px 0px rgba(130, 156, 195, 0.08)',
          borderRadius: '30px',
          '&:last-of-type': {
            borderRadius: '30px',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            width: '7px',
            height: '7px',
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            backgroundColor: '#96979A',
            borderRadius: '25px',
          },
          '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus':
            {
              backgroundColor: '#96979A',
            },
          '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active':
            {
              backgroundColor: '#96979A',
            },
          '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover':
            {
              backgroundColor: '#96979A',
            },
          '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
            backgroundColor: '#96979A',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiAutocomplete-clearIndicator': {
            color: theme.palette.secondary.dark,
          },
        }),

        popper: ({ theme }) => ({
          zIndex: 5,
          '& .MuiPaper-root': {
            margin: '3px 0px',
            backgroundColor: theme.palette.action.selected,
          },
          '& .MuiAutocomplete-listbox	': {
            maxHeight: 300,
            padding: 0,
            margin: '4px 0',
            '& li': {
              padding: '11px 21px',
              fontWeight: 500,
              '&:hover': {
                backgroundColor: theme.palette.primary.light,
              },
            },
          },
        }),
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltipArrow: ({ theme }) => ({
          padding: '9px 16px',
          backgroundColor: theme.palette.primary.main,
          fontSize: 14,
          fontWeight: 400,
          lineHeight: '18px',
          borderRadius: '8px',
          '& p': {
            fontWeight: 500,
            lineHeight: '140%',
          },
        }),
        arrow: ({ theme }) => ({
          color: theme.palette.primary.main,
          '&::before': {
            borderRadius: '2px',
          },
        }),
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          '& span': {
            padding: '0 25px',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0px 10px 10px 0px rgba(130, 156, 195, 0.08)',
          borderRadius: '30px',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 0,
        },
        indicator: ({ theme }) => ({
          backgroundColor: theme.palette.primary.main,
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          textTransform: 'none',
          fontSize: '16px',
          fontWeight: 400,
          color: theme.palette.text.primary,
          padding: '0 0 4px 0',
          minHeight: 0,
          minWidth: 0,
          marginRight: '48px',
          '&.Mui-selected': {
            fontWeight: 700,
          },
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: '8px',
          padding: '8px 6px',
          backgroundColor: theme.palette.primary.light,
          position: 'absolute',
        }),
        label: ({ theme }) => ({
          padding: 0,
          fontSize: 14,
          fontWeight: 500,
          color: theme.palette.primary.main,
        }),
        deleteIcon: ({ theme }) => ({
          padding: 0,
          fontSize: 16,
          margin: '0 0  0 8px',
          color: theme.palette.text.primary,
        }),
      },
    },
    MuiTableSortLabel: {
      defaultProps: {
        IconComponent: SortArrow,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          '&:hover': {
            color: 'inherit',
            '& .MuiTableSortLabel-icon': { opacity: 1 },
          },
          // '&.Mui-active': {
          //   color: theme.palette.primary.main,
          //   '& path': {
          //     fill: theme.palette.primary.main,
          //   },
          // },
        }),
        icon: {
          opacity: 1,
          marginLeft: '10px',
          flexShrink: 0,
        },
      },
    },
    MuiCircularProgress: {
      defaultProps: {
        color: 'inherit',
        size: 20,
      },
    },
  },
});
