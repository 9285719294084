import { useEffect, useRef } from 'react';

import * as Sentry from '@sentry/react';
import {
  NewVersionSnackbar,
  NewVersionSnackbarHandle,
} from 'components/Snackbar/NewVersionSnackbar';
import { api } from 'redux/api';

export const VersionController = () => {
  const [refetch, { data }] = api.endpoints.fetchVersion.useLazyQuery({
    refetchOnFocus: true,
    refetchOnReconnect: true,
  });

  const ref = useRef<NewVersionSnackbarHandle>(null);

  useEffect(() => {
    const handleWindowVisible = async () => {
      if (document.visibilityState === 'visible') {
        try {
          const localVersions = localStorage.getItem('versions');
          const parsedLocalVersions =
            localVersions && localVersions !== 'undefined'
              ? JSON.parse(localVersions)
              : null;

          const { versions: serverVersions } = await refetch().unwrap();

          const isSameVersions = serverVersions?.every(version =>
            parsedLocalVersions?.includes(version)
          );

          if (isSameVersions) {
            return;
          }

          ref?.current?.setOpen(true);
        } catch (error) {
          Sentry.captureException(error);
        }
      }
    };

    window.addEventListener('visibilitychange', handleWindowVisible);
    return () => {
      window.removeEventListener('visibilitychange', handleWindowVisible);
    };
  }, [refetch]);

  const onRefresh = () => {
    if (data?.versions) {
      localStorage.setItem('versions', JSON.stringify(data?.versions));
    }
    window.location.reload();
  };

  return <NewVersionSnackbar onRefresh={onRefresh} ref={ref} />;
};
