import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { Order } from 'hooks/useTableSorting';
import { useTranslation } from 'react-i18next';
import {
  LINKEDIN_SORT_KEYS,
  LinkedinParsedAsset,
  RunDetailsItem,
  UPDATE_STATUS,
} from 'redux/runHistory/types';

import { DescriptionCell } from './LinkedinDescriptionCell';
import { RunDetailsTableHead } from './LinkedinRunDetailsTableHead';
import { CampaignFormatLabels, LinkedinStatusLabels } from '../../constants';
import { EmptyHistory, EmptyImage } from '../../EmptyHistory';
import { StyledCell } from '../../TableCell';

type RunDetailsTableProps = {
  data: RunDetailsItem<LinkedinParsedAsset>[];
  order: Order;
  orderBy: LINKEDIN_SORT_KEYS;
  handleRequestSort: (property: LINKEDIN_SORT_KEYS) => void;
  onFilterClick: (
    e: React.MouseEvent<HTMLDivElement>,
    id: LINKEDIN_SORT_KEYS
  ) => void;
  isLoading: boolean;
};

export const RunDetailsTable: React.FC<RunDetailsTableProps> = ({
  data,
  order,
  orderBy,
  handleRequestSort,
  onFilterClick,
  isLoading,
}) => {
  const { t } = useTranslation();

  return (
    <Paper sx={{ width: '100%' }}>
      <Table stickyHeader>
        <RunDetailsTableHead
          order={order}
          orderBy={orderBy}
          handleRequestSort={handleRequestSort}
          onFilterClick={onFilterClick}
        />
        <TableBody>
          {data.map((row, i) => (
            <StyledTableRow key={`${row?.parsedAsset?.creativeId}${i}`}>
              <StyledCell>
                <CroppedName>{row?.parsedAsset?.campaignGroupName}</CroppedName>
              </StyledCell>
              <StyledCell>
                <CroppedName>{row?.parsedAsset?.campaignName}</CroppedName>
                <Typography variant="subtitle2" color="secondary.main">
                  {t('details.format')}{' '}
                  {t(
                    CampaignFormatLabels[
                      row?.parsedAsset?.campaignFormat as string
                    ]
                  ) || row?.parsedAsset?.campaignFormat}
                </Typography>
              </StyledCell>
              <StyledCell>
                <CroppedName>
                  {row?.parsedAsset?.creativeName || (
                    <Typography component="span">
                      {t('details.not_handled_level')}
                    </Typography>
                  )}
                </CroppedName>
              </StyledCell>
              <StyledCell>
                <DescriptionCell result={row} />
              </StyledCell>
              <StyledCell>
                <Typography
                  color={
                    row?.status === UPDATE_STATUS.ERROR ? 'error' : 'inherit'
                  }
                >
                  {t(LinkedinStatusLabels[row.status])}
                </Typography>
              </StyledCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      {!isLoading && data?.length === 0 && (
        <EmptyHistory
          image={EmptyImage.NOT_FOUND}
          title={t('empty_state.no_data')}
        />
      )}
    </Paper>
  );
};

const StyledTableRow = styled(TableRow)(() => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  '& td': {
    padding: '10px 0',
  },
  '& .MuiTypography-root': {
    lineHeight: 1.4,
  },
}));

const CroppedName = styled(Typography)({
  paddingRight: '15px',
  // textOverflow: 'ellipsis',
  // overflow: 'hidden',
  // whiteSpace: 'nowrap',
  // maxWidth: '200px',
});
