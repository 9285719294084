import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AnalyzeInfo, QueueState, ReanalyzeStatus } from './types';

const initialState: {
  taskQueue: Array<QueueState>;
  showDrawer: number;
} = {
  taskQueue: [],
  showDrawer: 0,
};

export const reanalyzeSlice = createSlice({
  name: 'reanalyze',
  initialState,
  reducers: {
    addTask: (state, action: PayloadAction<QueueState>) => {
      state.taskQueue.push(action.payload);
    },

    showDrawer: (state, action: PayloadAction<number>) => {
      state.showDrawer = action.payload;
    },

    closeDrawer: (state, action: PayloadAction<number>) => {
      state.showDrawer = 0;
      state.taskQueue = state.taskQueue.filter(
        item => item.id !== action.payload
      );
    },

    removeItem: (state, action: PayloadAction<number>) => {
      state.taskQueue = state.taskQueue.filter(
        item => item.id !== action.payload
      );
    },

    setStatus: (
      state,
      action: PayloadAction<{ id: number; status: ReanalyzeStatus }>
    ) => {
      state.taskQueue = state.taskQueue.map(item =>
        item.id === action.payload.id
          ? { ...item, status: action.payload.status }
          : item
      );
    },

    setSummary: (
      state,
      action: PayloadAction<{ id: number; summary: AnalyzeInfo }>
    ) => {
      state.taskQueue = state.taskQueue.map(item =>
        item.id === action.payload.id
          ? { ...item, summary: action.payload.summary }
          : item
      );
    },
  },
});

export const {
  addTask,
  showDrawer,
  removeItem,
  setStatus,
  setSummary,
  closeDrawer,
} = reanalyzeSlice.actions;

export default reanalyzeSlice.reducer;
