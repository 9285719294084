import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import { styled } from '@mui/system';

export const StyledMenuItem = styled((props: MenuItemProps) => (
  <MenuItem disableRipple {...props} />
))(({ theme }) => ({
  padding: '10px 18px 10px 13px',
  marginBottom: '6px',
  justifyContent: 'space-between',
  height: 37,
  '& .MuiTypography-root': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: 330,
  },
  '&:last-of-type': {
    marginBottom: 0,
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
    '& .MuiButton-root': {
      display: 'block',
    },
    '& .MuiTypography-root': {
      width: 270,
    },
  },
  '& .MuiButton-root': {
    display: 'none',
    backgroundColor: 'white',
    color: theme.palette.text.primary,
    height: 28,
    minWidth: 52,
    padding: '5px 9px',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));
