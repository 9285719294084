import { EPlatforms } from 'platformsConstants';
import { api } from 'redux/api';
import { Response } from 'redux/types';

import {
  ContentType,
  DefaultTemplateContent,
  DeleteArgsType,
  DynamicValue,
  GetArgsType,
  GetKeysType,
  ListDataType,
  PostArgsType,
  TemplateType,
} from './types';

export const urlTemplateApi = api.injectEndpoints({
  endpoints: builder => ({
    getUrlTemplates: builder.query<ContentType[], GetArgsType>({
      query: ({ platform, organization_id }) => ({
        url: `/${platform}/urlTemplates`,
        method: 'GET',
        params: { organization_id },
      }),
      providesTags: (result, error, arg) => [
        { type: 'urlTemplate', id: arg.platform },
      ],
      transformResponse: (response: Response<ContentType[]>) => {
        if (!response.content) return [];

        return response.content.map(platformTemplate => {
          const { urlTemplate } = platformTemplate;
          for (const param in urlTemplate) {
            if (urlTemplate[param].toBeDeleted) {
              delete urlTemplate[param];
            }
          }
          return platformTemplate;
        });
      },
    }),

    postUrlTemplate: builder.mutation<ContentType[], PostArgsType>({
      query: ({ organization_id, body }) => ({
        url: `/${body.platformType}/urlTemplate`,
        method: 'POST',
        body,
        params: { organization_id },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'urlTemplate', id: arg.body.platformType },
        { type: 'adAccount', id: arg.body.platformType },
      ],
      transformResponse: (response: Response<ContentType[]>) =>
        response.content,
    }),

    deleteUrlTemplate: builder.mutation<ContentType[], DeleteArgsType>({
      query: ({ organization_id, platform, templatesId }) => ({
        url: `/${platform}/urlTemplate`,
        method: 'DELETE',
        body: { templatesId },
        params: { organization_id },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'urlTemplate', id: arg.platform },
      ],
      transformResponse: (response: Response<ContentType[]>) =>
        response.content,
    }),

    getDefaultTemplate: builder.query<DefaultTemplateContent, EPlatforms>({
      query: platform => ({
        url: `/${platform}/defaultUrlTemplate`,
        method: 'GET',
      }),
      transformResponse: (
        response: Response<TemplateType>,
        meta,
        arg: EPlatforms
      ) => {
        const urlTemplate: TemplateType = response.content;
        return { urlTemplate, platform: arg };
      },
    }),

    getTemplateKeys: builder.query<string[], GetKeysType>({
      query: ({ platform, organization_id }) => ({
        url: `/${platform}/templateKeys`,
        params: { organization_id },
        method: 'GET',
      }),
      providesTags: () => [{ type: 'urlTemplate' }],
      transformResponse: (response: Response<string[]>) => response.content,
    }),

    getDynamicValues: builder.query<DynamicValue[], EPlatforms>({
      query: platform => ({
        url: `/${platform}/dynamicValues`,
        method: 'GET',
      }),
      providesTags: ['dynamicValues'],
      transformResponse: (response: Response<DynamicValue[]>) =>
        response.content,
    }),

    getDefaultHubSpotTemplate: builder.query<ListDataType[], EPlatforms>({
      query: platform => ({
        url: `/${platform}/hubSpotUrlTemplate`,
        method: 'GET',
      }),
      transformResponse: (response: Response<TemplateType>) =>
        Object.entries(response.content),
    }),
  }),
});

export const {
  useGetUrlTemplatesQuery,
  usePostUrlTemplateMutation,
  useGetTemplateKeysQuery,
  useGetDynamicValuesQuery,
  useDeleteUrlTemplateMutation,
  useGetDefaultTemplateQuery,
  useLazyGetDefaultTemplateQuery,
  useGetDefaultHubSpotTemplateQuery,
} = urlTemplateApi;
