import { useEffect } from 'react';

import { MsalProvider } from '@azure/msal-react';
import * as Sentry from '@sentry/react';
import { App } from 'App';
import { msalInstance } from 'components/MicrosoftButton/authConfig';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import { persistor, store } from 'redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import 'react-day-picker/dist/style.css';
import './components/Theme/DefaultDayPickerTheme.css';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

const tagManagerArgs = {
  gtmId: import.meta.env.VITE_GTM_ID as string,
  events: {
    'gtm.start': new Date().getTime(),
    event: 'gtm.js',
  },
};

TagManager.initialize(tagManagerArgs);

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [window.location.origin],
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay({
      networkDetailAllowUrls: [import.meta.env.VITE_BASE_URL as string],
    }),
  ],
  environment: import.meta.env.VITE_ENVIRONMENT,
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <MsalProvider instance={msalInstance}>
        <IntercomProvider
          shouldInitialize
          initializeDelay={1000}
          appId={import.meta.env.VITE_INTERCOM_APP_ID as string}
        >
          <App />
        </IntercomProvider>
      </MsalProvider>
    </PersistGate>
  </Provider>
);
