import { ThemeProvider } from '@mui/material';

import { themeOptions } from './Theme';

type ThemeProps = {
  children: JSX.Element | JSX.Element[];
};

export const Theme: React.FC<ThemeProps> = props => {
  return <ThemeProvider theme={themeOptions}>{props.children}</ThemeProvider>;
};
