import AnalysisIcon from 'assets/svg/analysis_icon_sidebar.svg?react';
import ConnectionsIcon from 'assets/svg/connections_icon.svg?react';
import HomeIcon from 'assets/svg/home_icon.svg?react';
import HubIcon from 'assets/svg/hub_icon.svg?react';
import RunHistory from 'assets/svg/run_history.svg?react';
import TemplateIcon from 'assets/svg/template_icon.svg?react';
import { PLATFORMS, ROUTES } from 'platformsConstants';

export const ITEMS_TO_DISABLE = [
  'sidebar.menu.templates',
  'sidebar.menu.connections',
  'sidebar.menu.ai_rules',
  'sidebar.menu.analysis',
];

export const MenuItemsAutoUTMMode: Array<{
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  label: string;
  navigate: string;
  selected: string[];
}> = [
  {
    Icon: HomeIcon,
    label: 'sidebar.menu.organization',
    navigate: 'organization/details',
    selected: ['organization'],
  },
  {
    Icon: HubIcon,
    label: 'sidebar.menu.adAccounts',
    navigate: `/${ROUTES.HOME}`,
    selected: [`${ROUTES.HOME}/empty-state`],
  },
  {
    Icon: TemplateIcon,
    label: 'sidebar.menu.templates',
    navigate: 'url-templates',
    selected: ['url-templates'],
  },
  {
    Icon: RunHistory,
    label: 'sidebar.menu.history',
    navigate: 'run-history',
    selected: ['run-history'],
  },
  {
    Icon: ConnectionsIcon,
    label: 'sidebar.menu.connections',
    navigate: `/${ROUTES.HOME}/connect/${PLATFORMS[0]}`,
    selected: [`${ROUTES.HOME}/connect`, `${ROUTES.HOME}/choose-account`],
  },
];

export const MenuItemsSearchTermMode: Array<{
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  label: string;
  navigate: string;
  selected: string[];
}> = [
  {
    Icon: HomeIcon,
    label: 'sidebar.menu.organization',
    navigate: 'organization/details',
    selected: ['organization'],
  },
  {
    Icon: HubIcon,
    label: 'sidebar.menu.adAccounts',
    navigate: `/${ROUTES.HOME}`,
    selected: [`${ROUTES.HOME}/empty-state`],
  },
  {
    Icon: TemplateIcon,
    label: 'sidebar.menu.ai_rules',
    navigate: 'ai-briefs',
    selected: ['ai-briefs'],
  },
  {
    Icon: AnalysisIcon,
    label: 'sidebar.menu.analysis',
    navigate: 'analysis/select-account',
    selected: ['analysis'],
  },
  {
    Icon: ConnectionsIcon,
    label: 'sidebar.menu.connections',
    navigate: `/${ROUTES.HOME}/connect/${PLATFORMS[0]}`,
    selected: [`${ROUTES.HOME}/connect`, `${ROUTES.HOME}/choose-account`],
  },
];
