import { createSimpleEvent, DataLayerEventName } from 'gtm';
import { TOKEN_KEY } from 'hooks/useGetToken';
import { api } from 'redux/api';
import { Response } from 'redux/types';
import { workspaceApi } from 'redux/workspace/workspaceApi';

import {
  DeleteBodyType,
  HandleInvitationType,
  InvitationContentType,
  InviteBodyType,
  OrganizationInfoType,
} from './types';

export const organizationApi = api.injectEndpoints({
  endpoints: builder => ({
    getOrganizationByUser: builder.query<OrganizationInfoType[], string>({
      query: user_id => ({
        url: `/api/v1/organizations`,
        method: 'GET',
        params: { user_id },
      }),
      providesTags: ['organization'],
      transformResponse: (response: Response<OrganizationInfoType[]>) =>
        response.content,
    }),

    getOrganizationInfo: builder.query<OrganizationInfoType, string>({
      query: id => ({
        url: `/api/v1/organizations/${id}`,
        method: 'GET',
      }),
      providesTags: ['organization'],
      transformResponse: (response: Response<OrganizationInfoType>) =>
        response.content,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data: organization } = await queryFulfilled;
          await dispatch(
            workspaceApi.endpoints.getWorkspaceByOrgId.initiate(
              organization.id,
              { forceRefetch: true }
            )
          );
        } catch (error) {
          console.log('error had been caught');
        }
      },
    }),

    putOrganizationInfo: builder.mutation<
      OrganizationInfoType,
      Partial<OrganizationInfoType>
    >({
      query: ({ id, ...body }) => ({
        url: `/api/v1/organizations/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['organization'],
      transformResponse: (response: Response<OrganizationInfoType>) =>
        response.content,
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled;
          createSimpleEvent(DataLayerEventName.UPDATE_ORG);
        } catch (error) {
          console.log('error had been caught');
        }
      },
    }),

    handleInvitation: builder.mutation<HandleInvitationType, string>({
      query: token => ({
        url: '/api/v1/users/handle-user-invitation',
        method: 'POST',
        body: { [TOKEN_KEY]: token },
      }),
      invalidatesTags: ['organization'],
      transformResponse: (response: Response<HandleInvitationType>) =>
        response.content,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data: content } = await queryFulfilled;

          await dispatch(
            workspaceApi.endpoints.getWorkspaceByOrgId.initiate(
              content?.organization?.id,
              { forceRefetch: true }
            )
          );
        } catch (error) {
          console.log('error had been caught');
        }
      },
    }),

    inviteUser: builder.mutation<OrganizationInfoType, InviteBodyType>({
      query: ({ organization_id, ...body }) => ({
        url: '/api/v1/users/invite-user',
        method: 'POST',
        body,
        params: { organization_id },
      }),
      invalidatesTags: ['organization'],
      transformResponse: (response: Response<InvitationContentType>) =>
        response.content.organization,
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled;
          createSimpleEvent(DataLayerEventName.INVITE_USER);
        } catch (error) {
          console.log('error had been caught');
        }
      },
    }),

    deleteUser: builder.mutation<OrganizationInfoType, DeleteBodyType>({
      query: ({ organization_id, email }) => ({
        url: '/api/v1/organizations/delete-user',
        method: 'DELETE',
        body: { email },
        params: { organization_id },
      }),
      invalidatesTags: ['organization'],
      transformResponse: (response: Response<OrganizationInfoType>) =>
        response.content,
    }),

    changeUserRole: builder.mutation<OrganizationInfoType, InviteBodyType>({
      query: ({ organization_id, ...body }) => ({
        url: '/api/v1/organizations/assign-user-role',
        method: 'PUT',
        body,
        params: { organization_id },
      }),
      invalidatesTags: ['organization'],
      transformResponse: (response: Response<OrganizationInfoType>) =>
        response.content,
    }),
  }),
});

export const {
  useGetOrganizationInfoQuery,
  usePutOrganizationInfoMutation,
  useGetOrganizationByUserQuery,
  useHandleInvitationMutation,
  useInviteUserMutation,
  useDeleteUserMutation,
  useChangeUserRoleMutation,
  useLazyGetOrganizationInfoQuery,
} = organizationApi;
