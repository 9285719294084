import { FC, ReactNode } from 'react';

import Box from '@mui/material/Box';
import TableContainer from '@mui/material/TableContainer';
import { styled } from '@mui/system';

type ScrollablePageProps = {
  header: ReactNode | ReactNode[];
  children: ReactNode | ReactNode[];
  headerHeight: number;
};

export const ScrollablePage: FC<ScrollablePageProps> = ({
  children,
  header,
  headerHeight,
}) => {
  return (
    <Container>
      <Header height={headerHeight}>{header}</Header>
      <ScrollableContainer sx={{ maxHeight: `calc(100% - ${headerHeight}px)` }}>
        {children}
      </ScrollableContainer>
    </Container>
  );
};

const ScrollableContainer = styled(TableContainer)(() => ({
  padding: '0 17px 0px 30px', // 17 as we have 7 for gutter and 6 for padding - total 30
  overflowY: 'auto',
  overflowX: 'visible',
  scrollbarGutter: 'stable',
}));

const Container = styled(Box)({
  height: '100%',
  marginLeft: '56px',
  padding: '22px 6px 0px 0px',
  position: 'relative',
});

const Header = styled(Box)({
  padding: '0 24px 0 30px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  position: 'relative',
});
