import Box from '@mui/material/Box';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import FilterIcon from 'assets/svg/filter_icon.svg?react';
import { Order } from 'hooks/useTableSorting';
import { useTranslation } from 'react-i18next';
import { LINKEDIN_SORT_KEYS } from 'redux/runHistory/types';

import { LinkedinDetailsColumns } from '../../constants';
import { StyledCell } from '../../TableCell';

type RunDetailsTableHeadProps = {
  order: Order;
  orderBy: LINKEDIN_SORT_KEYS;
  handleRequestSort: (property: LINKEDIN_SORT_KEYS) => void;
  onFilterClick: (
    e: React.MouseEvent<HTMLDivElement>,
    id: LINKEDIN_SORT_KEYS
  ) => void;
};

export const RunDetailsTableHead: React.FC<RunDetailsTableHeadProps> = ({
  order,
  orderBy,
  handleRequestSort,
  onFilterClick,
}) => {
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow sx={{ height: 43 }}>
        {LinkedinDetailsColumns.map(({ id, minWidth, label }) => (
          <StyledCell key={id} style={{ minWidth }}>
            <Box>
              <Box />
            </Box>
            <Box display={'flex'} alignItems={'center'}>
              <Box
                onClick={e => onFilterClick(e, id)}
                sx={{
                  cursor: 'pointer',
                  marginRight: '5px',
                  lineHeight: '16px',
                }}
              >
                <FilterIcon />
              </Box>
              <TableSortLabel
                active={orderBy === id}
                direction={orderBy === id ? order : Order.ASC}
                onClick={() => handleRequestSort(id)}
              >
                {t(label)}
              </TableSortLabel>
            </Box>
          </StyledCell>
        ))}
        <StyledCell style={{ minWidth: 400 }}>
          {t('history_table_heading.description')}
        </StyledCell>
        <StyledCell style={{ minWidth: 150 }}>
          <Box>
            <Box />
          </Box>
          {t('history_table_heading.status')}
        </StyledCell>
      </TableRow>
    </TableHead>
  );
};
