import { FC } from 'react';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import CheckTick from 'assets/svg/check_tick.svg?react';
import { useTranslation } from 'react-i18next';

import { StyledMenuItem } from './StyledMenuItem';

type CheckableMenuItemProps = {
  checked: boolean;
  label: string;
  onChange: () => void;
  onOnlyClick: () => void;
};

export const CheckableMenuItem: FC<CheckableMenuItemProps> = ({
  checked,
  label,
  onChange,
  onOnlyClick,
}) => {
  const { t } = useTranslation();

  return (
    <StyledMenuItem>
      <FormControlLabel
        control={
          <Checkbox
            onChange={onChange}
            checked={checked}
            checkedIcon={
              <Icon>
                <CheckTick />
              </Icon>
            }
            icon={<Icon />}
            disableRipple
            sx={{ padding: 0 }}
          />
        }
        label={<Typography ml="10px">{label}</Typography>}
      />
      <Button onClick={onOnlyClick}>{t('buttons.only')}</Button>
    </StyledMenuItem>
  );
};

const Icon = styled(Paper)({
  width: 17,
  height: 17,
  borderRadius: '4px',
  '& svg': {
    position: 'relative',
    left: 2,
  },
});
