import { memo, ReactNode } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import ArrowBack from 'assets/svg/arrow_back.svg?react';
import { SplitButton } from 'components/SplitButton/SplitButton';
import { EPlatforms, ROUTES } from 'platformsConstants';
import { Link, useLocation } from 'react-router-dom';

type MainHeaderProps = {
  title: ReactNode | ReactNode[] | string;
  link?: string;
  button?: string;
  onClick?: () => void;
  disabled?: boolean;
  onSplitButtonClick?: (platform: EPlatforms) => void;
  isSplitLoading?: boolean;
};

export const MainHeader = memo<MainHeaderProps>(
  ({
    title,
    link,
    button,
    onClick,
    disabled,
    onSplitButtonClick,
    isSplitLoading,
  }) => {
    const { state } = useLocation();

    return (
      <HeaderContainer>
        {link && (
          <Link
            to={state?.from || link || `/${ROUTES.HOME}`}
            state={{ ...state, from: null, opened: true }}
          >
            <Box>
              <ArrowBack />
            </Box>
          </Link>
        )}
        <Typography variant="h2">{title}</Typography>
        {button && (
          <Button
            sx={{ padding: '11px 30px 12px', minWidth: '140px' }}
            variant="contained"
            onClick={onClick}
            disabled={disabled}
          >
            {button}
          </Button>
        )}
        {onSplitButtonClick && (
          <SplitButton loading={isSplitLoading} onClick={onSplitButtonClick} />
        )}
      </HeaderContainer>
    );
  }
);

const HeaderContainer = styled(Box)({
  height: 40,
  marginBottom: '13px',
  display: 'flex',
  alignItems: 'center',
  '&  button': {
    marginLeft: 'auto',
  },
  '&  p': {
    marginLeft: 'auto',
  },
  '&  a': {
    lineHeight: 0,
    marginRight: '10px',
  },
});
