export type SuggestBrandElementsDto = {
  brand_name?: string;
  brand_website?: string;
  organization_id: string;
};

export interface SingleBrandElements {
  brand_name: string;
  brand_website: string;
  brand_short_description: string;
  brand_competitors_list: Array<string>;
  products: Array<{
    name: string;
    description: string;
  }>;
}

export enum AIRulesCategory {
  PRODUCT = 'product',
  BRAND = 'brand',
}

export enum AIRulesSource {
  AIAssistant = 'AIAssistant',
  User = 'User',
}

export interface AIRule {
  search_term: string;
  category: AIRulesCategory;
}

export interface SingleBrandAIRules {
  excluded_search_terms: Array<AIRule>;
  perfect_search_terms: Array<AIRule>;
  general_rules: Array<string>;
}

export interface TransformedSingleBrandAIRules {
  search_terms: Array<SearchTerm>;
  general_rules: Array<string>;
}
export interface Product {
  id?: string;
  name: string;
  description: string;
}

export interface Brand {
  id: string;
  briefName: string;
  organizationId: string;
  name: string;
  website: string;
  shortDescription: string;
  competitorsList: Array<string>;
  adAccounts: Array<string>;
  createdAt: Date;
  updatedAt: Date;
  products: Array<Product>;
  createdBy:
    | string
    | {
        _id: string;
        firstName: string;
        lastName: string;
      };
}

export type CreateBrandDto = Pick<
  Brand,
  | 'organizationId'
  | 'adAccounts'
  | 'briefName'
  | 'name'
  | 'website'
  | 'shortDescription'
  | 'competitorsList'
  | 'products'
>;

export type SearchTerm = {
  searchTerm: string;
  isExclude: boolean;
  category: AIRulesCategory;
  source: AIRulesSource;
  updatedAt: string;
  id: string;
  inUse: boolean;
};

export interface AIRules {
  id: string;
  organizationId: string;
  brandId: string;
  rules: Array<string>;
  searchTermsExcludeExamples: Array<SearchTerm>;
  createdAt?: Date;
  updatedAt?: Date;
}

export type CreateAIRulesDto = Pick<
  AIRules,
  'organizationId' | 'brandId' | 'rules' | 'searchTermsExcludeExamples'
>;

export type PatchAIRulesDto = Partial<AIRules>;
