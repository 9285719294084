import { EPlatforms } from 'platformsConstants';
import { api } from 'redux/api';
import { Response } from 'redux/types';

import {
  GetLastRunType,
  GetValidationJobSummary,
  JobResponse,
  RequestType,
  RunJobArgsType,
  RunJobContentType,
  UpdateApi,
  ValidationJobDTO,
} from './types';

export const getJobApi = (platform: EPlatforms) => {
  switch (platform) {
    case EPlatforms.GOOGLE:
      return import.meta.env.VITE_GOOGLE_JOB_API as UpdateApi;
    case EPlatforms.LINKEDIN:
      return import.meta.env.VITE_LINKEDIN_JOB_API as UpdateApi;
  }
};

const TIMEOUT = 1000 * 60 * 3;

export const jobApi = api.injectEndpoints({
  endpoints: builder => ({
    getLastRun: builder.query<GetLastRunType, RequestType>({
      query: ({ platform, organization_id }) => ({
        url: `/${platform}/job/autoUTM/jobruns`,
        method: 'GET',
        params: { organization_id },
      }),
      providesTags: (result, error, arg) => [{ type: 'job', id: arg.platform }],
      transformResponse: (response: Response<GetLastRunType>) =>
        response.content,
    }),

    getJobRunsForTermsValidation: builder.query<
      GetValidationJobSummary,
      { organization_id: string }
    >({
      query: ({ organization_id }) => ({
        url: `/google/job/autoSearchTermValidation/jobruns`,
        method: 'GET',
        params: { organization_id },
      }),
      providesTags: () => ['SearchTermValidationJob'],
      transformResponse: (response: Response<GetValidationJobSummary>) =>
        response.content,
    }),

    runSearchTermValidationJob: builder.mutation<JobResponse, ValidationJobDTO>(
      {
        query: ({ organization_id, adAccounts }) => ({
          url: `/google/searchTerms/adAccounts/validate`,
          method: 'POST',
          params: { organization_id },
          body: { adAccounts },
        }),
        invalidatesTags: () => ['SearchTermValidationJob'],
        transformResponse: (response: Response<JobResponse>) =>
          response.content,
      }
    ),

    runJob: builder.mutation<RunJobContentType, RunJobArgsType>({
      query: ({
        platform,
        organization_id,
        update_api = getJobApi(platform),
        adAccounts,
      }) => ({
        url: `/${platform}/ads/autoUrlTemplate`,
        method: 'POST',
        params: { organization_id, update_api },
        body: { adAccounts },
        timeout: TIMEOUT,
      }),
      invalidatesTags: (result, error, arg) => {
        if (result?.isConnectionError && !result?.summary) {
          return [
            { type: 'adAccount', id: arg.platform },
            {
              type: 'platformAccount',
              id: `${arg.platform}${arg.organization_id}`,
            },
          ];
        } else if (!result?.isConnectionError && result?.summary) {
          return [{ type: 'job', id: arg.platform }];
        } else {
          return [
            { type: 'adAccount', id: arg.platform },
            {
              type: 'platformAccount',
              id: `${arg.platform}${arg.organization_id}`,
            },
            { type: 'job', id: arg.platform },
          ];
        }
      },
      transformResponse: (response: Response<RunJobContentType>) =>
        response.content,
    }),
  }),
});

export const {
  useGetLastRunQuery,
  useRunJobMutation,
  useGetJobRunsForTermsValidationQuery,
  useRunSearchTermValidationJobMutation,
} = jobApi;
