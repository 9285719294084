import { memo } from 'react';

import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import LInLogo from 'assets/images/Sign-In-Large---Default.png';

type LinkedInButtonProps = {
  onClick?: () => void;
};

export const LinkedInButton = memo<LinkedInButtonProps>(({ onClick }) => {
  return (
    <StyledButton onClick={onClick} variant="contained">
      <Box height={40} component="img" src={LInLogo}></Box>
    </StyledButton>
  );
});

const StyledButton = styled(Button)<ButtonProps>(() => ({
  padding: '0px',
}));
