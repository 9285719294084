import { memo, useRef, useState } from 'react';

import { Typography, TypographyProps } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { BaseFilterComponent } from 'components/Filters/components/BaseFilterComponent';
import { PopoverHandle } from 'components/Filters/components/FilterPopper';
import { StatusFilterBar } from 'components/Filters/StatusFilterBar';
import {
  JOB_SOURCE_ICON,
  LinkedinStatusLabels,
  RUN_TIME_FORMAT,
} from 'components/HistoryTable/constants';
import { EmptyHistory, EmptyImage } from 'components/HistoryTable/EmptyHistory';
import { JobNavigation } from 'components/HistoryTable/RunDetails/JobNavigation';
import { RunDetailsTable } from 'components/HistoryTable/RunDetails/Linkedin/LinkedinRunDetailsTable';
import { MainHeader } from 'components/MainHeader/MainHeader';
import { Pagination } from 'components/Pagination/Pagination';
import { ScrollablePage } from 'components/ScrollablePage/ScrollablePage';
import { Order, ORDER_TRANSFORM, useTableSorting } from 'hooks/useTableSorting';
import moment from 'moment';
import { EPlatforms, ICONS, ROUTES } from 'platformsConstants';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import {
  useGetLinkedinDetailsMetadataQuery,
  useGetLinkedinDetailsQuery,
} from 'redux/runHistory/runHistoryApi';
import {
  JOB_SOURCE,
  LINKEDIN_SORT_KEYS,
  MetaSummary,
  UPDATE_STATUS,
} from 'redux/runHistory/types';

export const INITIAL_QUERY_PARAMS = {
  sortBy: LINKEDIN_SORT_KEYS.CREATIVE_NAME,
  sortOrder: Order.DESC,
  offset: 0,
  limit: 20,
  statuses: [],
  campaignGroupIds: [],
  campaignIds: [],
  creativeIds: [],
};

const HEADER_HEIGHT = 160;

export const RunDetails = memo(() => {
  const { t } = useTranslation();

  const { adAccountId: accountId = '', platform } = useParams<{
    adAccountId: string;
    platform: EPlatforms;
  }>();

  const { state } = useLocation();

  const initialJobSource = state?.info?.jobSource as JOB_SOURCE;
  const jobDate = state?.info?.createdAt;
  const accountName = state?.info?.accountName;
  const initialJobId = state?.info?.jobId;
  const initialStatus = state?.filter as UPDATE_STATUS;

  const organization_id = useAppSelector(
    state => state.auth.user.organization_id
  );
  const workspace_id = useAppSelector(state => state.auth.user.workspace_id);

  const { order, orderBy, handleRequestSort } =
    useTableSorting<LINKEDIN_SORT_KEYS>({
      initialOrderBy: INITIAL_QUERY_PARAMS.sortBy,
      initialOrder: INITIAL_QUERY_PARAMS.sortOrder,
    });

  const sortOrder = ORDER_TRANSFORM[order] * -1;

  const [offset, setOffset] = useState(INITIAL_QUERY_PARAMS.offset);
  const [limit] = useState(INITIAL_QUERY_PARAMS.limit);

  const [statuses, setStatuses] = useState<UPDATE_STATUS[]>(
    initialStatus ? [initialStatus] : INITIAL_QUERY_PARAMS.statuses
  );

  const [campaignGroupIds, setCampaignGroupIds] = useState<string[]>(
    INITIAL_QUERY_PARAMS.campaignGroupIds
  );

  const [campaignIds, setCampaignIds] = useState<string[]>(
    INITIAL_QUERY_PARAMS.campaignIds
  );

  const [creativeIds, setCreativeIds] = useState<string[]>(
    INITIAL_QUERY_PARAMS.creativeIds
  );

  const [jobId, setJobId] = useState<string>(initialJobId);

  const [createdAt, setCreatedAt] = useState<string>(
    moment(jobDate).toISOString()
  );

  const [jobSource, setJobSource] = useState<JOB_SOURCE>(
    initialJobSource || JOB_SOURCE.MANUAL
  );

  const {
    data: metaData,
    isFetching: isMetaLoading,
    isSuccess,
  } = useGetLinkedinDetailsMetadataQuery(
    {
      organization_id,
      accountId,
      jobId,
      createdAt,
    },
    {
      skip: !workspace_id || !organization_id || !accountId || !jobId,
      refetchOnMountOrArgChange: true,
    }
  );

  const isEmptyFilteredStatus =
    metaData?.meta?.summary?.[statuses[0] as keyof MetaSummary] === 0;

  const { data, isFetching } = useGetLinkedinDetailsQuery(
    {
      organization_id,
      accountId,
      jobId,
      statuses,
      sortOrder,
      sortBy: orderBy,
      offset,
      limit,
      campaignGroupIds,
      campaignIds,
      creativeIds,
    },
    {
      skip: !workspace_id || !organization_id || !accountId || !jobId,
    }
  );

  const totalItems = data?.info?.totalCount || 0;

  const popperCampaignGroupRef = useRef<PopoverHandle>(null);
  const popperCampaignRef = useRef<PopoverHandle>(null);
  const popperCreativeRef = useRef<PopoverHandle>(null);

  const Filters = {
    [LINKEDIN_SORT_KEYS.CAMPAIGN_GROUP_NAME]: popperCampaignGroupRef,
    [LINKEDIN_SORT_KEYS.CAMPAIGN_NAME]: popperCampaignRef,
    [LINKEDIN_SORT_KEYS.CREATIVE_NAME]: popperCreativeRef,
  };

  const toggleBaseFilter = (
    e: React.MouseEvent<HTMLDivElement>,
    id: LINKEDIN_SORT_KEYS
  ) => {
    Filters[id].current?.togglePopper(e);
  };

  const emptyStateTitle =
    statuses[0] === UPDATE_STATUS.ERROR
      ? t('empty_state.success')
      : t('empty_state.no_ads', {
          status: t(LinkedinStatusLabels[statuses[0]]),
        });

  return (
    <ScrollablePage
      headerHeight={HEADER_HEIGHT}
      header={
        <>
          <Box>
            <MainHeader
              link={`/${ROUTES.HOME}`}
              title={
                <>
                  <SourceIcon>{JOB_SOURCE_ICON[jobSource]}</SourceIcon>
                  {t('details.heading')}{' '}
                  {Boolean(createdAt) && (
                    <Typography component="span" variant="h4">
                      {moment(createdAt).format(RUN_TIME_FORMAT)}
                    </Typography>
                  )}
                </>
              }
            />
            <Box display="flex" alignItems="center">
              {!!platform && (
                <Box
                  component="img"
                  src={ICONS[platform]}
                  alt={ICONS[platform]}
                  height={18}
                  width={18}
                  sx={{ marginRight: '5px', transform: 'translateY(-2px)' }}
                />
              )}
              {Boolean(accountName) && (
                <Typography fontWeight={700}>
                  {accountName}{' '}
                  <Typography component="span">{`ID: ${accountId}`}</Typography>
                </Typography>
              )}
            </Box>
            <StatusFilterBar
              statuses={statuses}
              setStatuses={setStatuses}
              summary={metaData?.meta?.summary || {}}
            />
          </Box>
          {!!metaData && (
            <JobNavigation
              prevJobInfo={metaData.prevJobInfo}
              nextJobInfo={metaData.nextJobInfo}
              setJobId={setJobId}
              setCreatedAt={setCreatedAt}
              setJobSource={setJobSource}
            />
          )}
        </>
      }
    >
      {isEmptyFilteredStatus ? (
        <EmptyHistory
          title={t(emptyStateTitle)}
          image={
            statuses[0] === UPDATE_STATUS.ERROR
              ? EmptyImage.SUCCESS
              : EmptyImage.GENERAL
          }
        />
      ) : (
        <RunDetailsTable
          data={data?.data || []}
          order={order}
          orderBy={orderBy}
          handleRequestSort={handleRequestSort}
          onFilterClick={toggleBaseFilter}
          isLoading={isFetching || isMetaLoading}
        />
      )}
      <Pagination
        offset={offset}
        setOffset={setOffset}
        total={totalItems}
        limit={limit}
      />
      {isSuccess && (
        <BaseFilterComponent
          ref={popperCampaignGroupRef}
          items={campaignGroupIds}
          setItems={setCampaignGroupIds}
          list={metaData?.meta?.uniqueCampaignGroups || []}
        />
      )}
      {isSuccess && (
        <BaseFilterComponent
          ref={popperCampaignRef}
          items={campaignIds}
          setItems={setCampaignIds}
          list={metaData?.meta?.uniqueCampaigns || []}
        />
      )}
      {isSuccess && (
        <BaseFilterComponent
          ref={popperCreativeRef}
          items={creativeIds}
          setItems={setCreativeIds}
          list={metaData?.meta?.uniqueCreatives || []}
          saturate={false}
        />
      )}
    </ScrollablePage>
  );
});

export default RunDetails;

const SourceIcon = styled((props: TypographyProps) => (
  <Typography component="span" {...props} />
))({
  marginRight: '5px',
  lineHeight: 0,
  '& svg': {
    width: '16px',
    height: '16px',
  },
});
