import { memo } from 'react';

import { ROUTES } from 'platformsConstants';
import { Navigate } from 'react-router-dom';
import { selectIsLoggedIn } from 'redux/auth/selectors';
import { useAppSelector } from 'redux/hooks';

type Props = {
  component: JSX.Element;
  redirectTo?: string;
};

export const RestrictedRoute = memo<Props>(
  ({ component: Component, redirectTo = `/${ROUTES.HOME}` }) => {
    const isLoggedIn = useAppSelector(selectIsLoggedIn);

    return isLoggedIn ? <Navigate to={redirectTo} /> : Component;
  }
);
