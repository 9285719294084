import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { api } from 'redux/api';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { appModeReducer } from './appMode/appModeSlice';
import { authReducer } from './auth/authSlice';
import { subscriptionReducer } from './billing/subscriptionSlice';
import jobReducer from './job/jobSlice';
import { platformAccountsReducer } from './platformAccounts/platformAccountsSlice';
import reanalyzeReducer from './searchTermsAnalysis/searchTermAnalysisSlice';
import { singleErrorReducer } from './singleErrorHandlerSlice/singleErrorHandlerSlice';
import { trackingTemplateReducer } from './urlTemplate/urlTemplateSlice';

const authPersistConfig = {
  key: 'UTM_user',
  storage,
  whitelist: ['user', 'isLoggedIn'],
};

const draftTemplatesPersistConfig = {
  key: 'UTM_templates',
  storage,
};

const appModePersistConfig = {
  key: 'UTM_appMode',
  storage,
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  platformAccounts: platformAccountsReducer,
  [api.reducerPath]: api.reducer,
  trackingTemplate: persistReducer(
    draftTemplatesPersistConfig,
    trackingTemplateReducer
  ),
  singleError: singleErrorReducer,
  subscription: subscriptionReducer,
  appMode: persistReducer(appModePersistConfig, appModeReducer),
  job: jobReducer,
  reanalyze: reanalyzeReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(api.middleware),
});

export const persistor = persistStore(store);
setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
