import GoogleRunDetails from 'components/HistoryTable/RunDetails/Google/GoogleRunDetailsPage';
import LinkedinRunDetails from 'components/HistoryTable/RunDetails/Linkedin/LinkedinRunDetailsPage';
import { EPlatforms } from 'platformsConstants';
import { useParams } from 'react-router-dom';

export const RunDetails = () => {
  const { platform } = useParams<{ platform: EPlatforms }>();

  return platform === EPlatforms.GOOGLE ? (
    <GoogleRunDetails />
  ) : (
    <LinkedinRunDetails />
  );
};
