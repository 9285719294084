import { Dispatch, FC, SetStateAction } from 'react';

import CloseIcon from '@mui/icons-material/CloseRounded';
import InputBase from '@mui/material/InputBase';
import Paper, { PaperProps } from '@mui/material/Paper';
import { styled } from '@mui/system';
import SearchIcon from 'assets/svg/search_icon.svg?react';
import { useTranslation } from 'react-i18next';

type FilterSearchFieldProps = {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
};

export const FilterSearchField: FC<FilterSearchFieldProps> = ({
  value,
  setValue,
}) => {
  const { t } = useTranslation();

  return (
    <StyledForm>
      <SearchIcon />
      <InputBase
        value={value}
        onChange={e => setValue(e.target.value)}
        sx={{ ml: '6px', flex: 1 }}
        placeholder={t('choose.placeholder')}
        type="text"
        endAdornment={
          value.length > 0 && (
            <CloseIcon
              sx={{ cursor: 'pointer', color: 'secondary.dark' }}
              onClick={() => {
                setValue('');
              }}
            />
          )
        }
      />
    </StyledForm>
  );
};

interface StyledPaperProps extends PaperProps {
  component?: string;
}

const StyledForm = styled(({ ...props }) => (
  <Paper {...props} component="form" />
))<StyledPaperProps>(() => ({
  padding: '12px',
  display: 'flex',
  alignItems: 'center',
  width: 300,
  height: 40,
  borderRadius: '12px',
  margin: '12px 13px',
}));
