import {
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { BaseQueryFn } from '@reduxjs/toolkit/query/react';
import { createApi } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import { RootState } from 'redux/store';

import { setIsShown } from './singleErrorHandlerSlice/singleErrorHandlerSlice';
import { Response, VersionsResponse } from './types';

const UNAUTHORIZED = 401;
const SERVER_ERROR = 500;
const BAD_REQUEST = 400;
const TOO_MUCH_REQUEST = 429;

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_BASE_URL,
  credentials: 'include',
  prepareHeaders: headers => {
    headers.set('browser-lang', window.navigator.language);
    headers.set('device-plat', window.navigator.platform);
    headers.set('screen-height', window.screen.availHeight.toString());
    headers.set('screen-width', window.screen.availWidth.toString());
    return headers;
  },
});

export const axiosBaseQuery =
  (): BaseQueryFn<FetchArgs, unknown, FetchBaseQueryError> =>
  async (requestOptions, api, extraOptions) => {
    const { workspace_id } = (api.getState() as RootState).auth.user;

    await mutex.waitForUnlock();

    let result;
    let url;

    const isManualJobEndpoint = api.endpoint === 'runJob';

    if (isManualJobEndpoint) {
      url = `${import.meta.env.VITE_BASE_JOB_URL}${requestOptions.url}`;
    }

    result = await baseQuery(
      {
        ...requestOptions,
        ...(url && { url }),
        params: { workspace_id, ...requestOptions.params },
      },
      api,
      extraOptions
    );

    const isUnauthorizedError =
      result.error && result.error.status === UNAUTHORIZED;

    if (isManualJobEndpoint && isUnauthorizedError) {
      result = await baseQuery(
        {
          ...requestOptions,
          params: { workspace_id, ...requestOptions.params },
        },
        api,
        extraOptions
      );
    }

    if (isUnauthorizedError) {
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();
        try {
          const refreshResult = await baseQuery(
            '/api/v1/users/refreshToken',
            api,
            extraOptions
          );

          if (refreshResult.data) {
            result = await baseQuery(
              {
                ...requestOptions,
                params: { workspace_id, ...requestOptions.params },
              },
              api,
              extraOptions
            );
          } else {
            api.dispatch({ type: 'auth/logOut' });
            api.dispatch({ type: 'singleError/setIsShown', payload: false });
          }
        } finally {
          release();
        }
      } else {
        await mutex.waitForUnlock();

        result = await baseQuery(
          {
            ...requestOptions,
            params: { workspace_id, ...requestOptions.params },
          },
          api,
          extraOptions
        );
      }
    }

    if (result.error && result.error.status === SERVER_ERROR) {
      api.dispatch(setIsShown(true));
    }

    if (result.error) {
      return {
        error: {
          status: result.error.status as number,
          data: result.error.data,
        },
      };
    }

    return { data: result.data };
  };

export const api = createApi({
  reducerPath: 'autoUTM',
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    'adAccount',
    'platformAccount',
    'urlTemplate',
    'templateKeys',
    'dynamicValues',
    'organization',
    'workspace',
    'user',
    'job',
    'subscription',
    'runHistory',
    'runDetails',
    'notSupported',
    'brand',
    'ai-rules',
    'SearchTermValidationResults',
    'negative-planner',
    'SearchTermValidationJob',
    'SearchTermNgramResults',
  ],
  endpoints: builder => ({
    fetchVersion: builder.query<VersionsResponse, void>({
      query: () => ({
        url: '/api/v1/versions',
        method: 'GET',
      }),
      transformResponse: (response: Response<VersionsResponse>) =>
        response.content,
    }),
  }),
});

export const isBadRequestErrorStatus = (error: unknown) =>
  typeof error === 'object' &&
  error !== null &&
  'status' in error &&
  error.status === BAD_REQUEST;

export const isTooMuchRequestErrorStatus = (error: unknown) =>
  typeof error === 'object' &&
  error !== null &&
  'status' in error &&
  error.status === TOO_MUCH_REQUEST;
