import { memo } from 'react';

import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import svgTerrificMainLogo from 'assets/svg/terrificMainLogo.svg';

type AuthContainerProps = {
  children: JSX.Element | JSX.Element[];
};

export const AuthContainer = memo<AuthContainerProps>(({ children }) => {
  return (
    <StyledContainer>
      <Box
        component="img"
        alt="company main logo"
        src={svgTerrificMainLogo}
        width={107.699}
      />
      <StyledContent>{children}</StyledContent>
    </StyledContainer>
  );
});

const StyledContent = styled(Box)(({ theme }) => ({
  margin: '0 auto',
  paddingTop: 30,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '& .MuiDivider-root::before': {
    top: 0,
  },
  '& .MuiDivider-root::after': {
    top: 0,
  },
  '& .MuiDivider-root': {
    top: 0,
    width: 450,
  },
  [theme.breakpoints.down('md')]: {
    '& .MuiDivider-root': {
      margin: '20px auto',
    },
  },
  [theme.breakpoints.only('md')]: {
    '& .MuiDivider-root': {
      margin: '20px auto',
    },
  },
  [theme.breakpoints.up('lg')]: {
    '& .MuiDivider-root': {
      margin: '32px auto',
    },
  },
}));

const StyledContainer = styled(Box)(() => ({
  height: '100vh',
  '& > img': {
    display: 'block',
    paddingTop: 40,
    margin: '0 auto',
  },
}));
