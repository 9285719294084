import { SortableKeys } from 'hooks/useAdAccountsTableSorting';

export const AutoUtmModeSortableHeadCells: HeadCell[] = [
  {
    id: SortableKeys.RESOURCE_NAME,
    label: 'adAccounts.header.adAccount',
  },
  {
    id: SortableKeys.CONNECTED_AT,
    label: 'adAccounts.header.connected',
  },
  {
    id: SortableKeys.LAST_RUN,
    label: 'adAccounts.header.last_run',
  },
  {
    id: SortableKeys.NEXT_RUN,
    label: 'adAccounts.header.next_run',
  },
];

export const SearchTermModeSortableHeadCells: HeadCell[] = [
  {
    id: SortableKeys.RESOURCE_NAME,
    label: 'adAccounts.header.adAccount',
  },
  {
    id: SortableKeys.CONNECTED_AT,
    label: 'adAccounts.header.connected',
  },
  {
    id: SortableKeys.LAST_RUN_SEARCH_TERM,
    label: 'adAccounts.header.last_run',
  },
  {
    id: SortableKeys.NEXT_RUN_SEARCH_TERM,
    label: 'adAccounts.header.next_run',
  },
  {
    id: SortableKeys.PERIOD_SUMMARY,
    label: 'Bad impressions',
  },
];

type HeadCell = {
  id: SortableKeys;
  label: string;
};
