import { api } from 'redux/api';
import { Response } from 'redux/types';

import { WorkspaceInfoType } from './types';

export const workspaceApi = api.injectEndpoints({
  endpoints: builder => ({
    getWorkspaceByOrgId: builder.query<WorkspaceInfoType[], string>({
      query: organization_id => ({
        url: `/api/v1/workspaces`,
        method: 'GET',
        params: { organization_id },
      }),
      providesTags: ['workspace'],
      transformResponse: (response: Response<WorkspaceInfoType[]>) =>
        response.content,
    }),
  }),
});

export const { useGetWorkspaceByOrgIdQuery } = workspaceApi;
